*{
  box-sizing: border-box;
  image-rendering: pixelated !important;
}

body {
  background: url('./assets/website_bg.png');
  background-repeat: repeat;
}
button {
  cursor: pointer;
}
button:disabled {
  opacity: 0.4;
}


button.connectWallet {
  padding: 10px 11px;
  font-weight: bold;
  color: #358933;
  border: solid 1px #358933;
  border-radius: 50px;
  background: transparent;
  font-size: 12px;
  position: absolute;
  top: 15px;
  right: 28px;
}

.connectWallet.reversed {
  color: #0F2E16;
  background:#358933;
  border: solid 1px #358933;
}

.pixelArt {
  font-family: "PixelFont"
}
a.pixelArt {
  text-decoration: none;
}

.text-secondary {
  color: #358933;
  fill: #358933;
}

.mainHeader {
  width: 100%;
  max-width: 808px;
  font-size: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-inline: auto;
  padding: 33px 20px;
  gap: 10px;
}

.logo {
  font-family: "PixelFont";
  font-size: 19px;
  text-align: center;
  text-decoration: none;
}
.circle-picker {
  width: 330px !important;
  margin-bottom: -7px !important;
  margin-right: -7px !important;
}
.circle-picker > span > div {
  width: 12px !important;
  height: 12px !important;
  margin-bottom: 4px !important;
  margin-right: 8px !important;
}

.circle-picker > span:nth-child(16n + 11) > div {
  padding-right: 8px !important;
  width: 20px !important;
}


.drawWrapper {
  background: rgba(0,0,0,0.3);
  border-radius: 10px;
  padding: 24px 16px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 18px;
  align-items: center;
  position:relative;
  width: 100%;
  max-width: 419px;
}

.drawTools {
  position: absolute;
  top: 14px;
  right: 13px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.drawTools button {
  background: transparent;
  padding: 0;
}

.drawTools button svg {
  fill: #595959;
}

.drawTools button.active svg {
  fill: white;
}

.namePickle {
  border-radius: 50px;
  font-size: 12px;
  color: rgba(255,255,255,0.5);
  background: rgba(0,0,0,0.5);
  text-align: center;
  width: 100%;
  padding: 6px;
  border:none
}

.submitDraw {
  margin-top: 15px;
  background: transparent;
  font-family: PixelFont;
  font-size: 16px;
  border-radius: 25px;
  border: 1px solid;
  padding: 8px;
  width: 100%;
}

.rulesText {
  color:#358933;
  font-size: 12px;
  max-width: 320px;
  margin-top: 80px;
  margin-left: 20px;
}

.rulesText h3 {
  font-family: "PixelFont";
  margin-top: 20px;
}

.rulesText p {
  margin-bottom: 20px;
  margin-top:0px;
}

.rulesText a {
  color:#358933;
}

.rulesText.hidesmall {
  visibility: hidden;
}

.lowEffortNote {
  margin-top: 180px;
  font-size: 10px;
}

.infoPage {
  padding: 40px;
  margin-top: 0;
}
.infoPage > * {
  width: 100%
}

.infoPage p {
  margin-top: 0;
  margin-bottom: 20px;
}
.infoPage h3 {
  cursor: pointer;
  margin-top: 0;
  margin-bottom: 20px;
}

.infoPage table {
  width: 100%;
}

.infoPage td {
  width: 25%;
  text-align: center;
}

.infoPage td:first-child {
text-align: left;
}
.showMobile {
  display: none !important;
}
@media screen and (max-width: 620px) {
  .showMobile {
    display: block !important;
  }
  .hideMobile {
    display: none !important;
  }
  .mobileColumns {
    flex-direction: column !important;
  }
  #connectWalletButton {
    font-size: "14px"
  }
  .connectWallet {
    top: 120px !important;
    right: 120px !important;
  }

.infoPage {
  padding: 10px;
}  
}

@media screen and (max-width:1170px) {
  .rulesText.hidesmall {
    display: none;
  }
  .lowEffortNote {
    margin-top: 30px;
  }
  .rulesText {
    margin-top: 10px;
  }
}

.spinner {
  animation: rotation 0.5s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@media screen and (max-width: 800px) {
  .mediumFlexWrap {
    flex-wrap: wrap;
    justify-content: center;
  }
}